<template>
  <section class="container text-center relative z-10">
    <h2 class="hidden lg:block" v-text="title"/>
    <div class="grid lg:grid-cols-3 gap-12 xxl:gap-48 mt-5 mx-4 sm:mx-32 lg:mx-24 xxl:mx-32">
      <div v-for="card in cards" :key="card.title" class="link-item">
        <h3 class="text-2xl py-6" v-text="card.title"/>
        <p class="pb-8 leading-loose" v-text="card.content"/>
        <nuxt-link :to="card.link" class="btn w-9/12 lg:w-8/12 no-underline">
          <div class="btn__text" v-text="card.title"/>
          <div class="btn__icon">
            <font-awesome-icon :icon="['fas', 'long-arrow-alt-right']"/>
          </div>
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    cms: {
      type: String,
      default: '',
    },
    erp: {
      type: String,
      default: '',
    },
    crm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      title: 'Koppelingen',
      cards: [
        {
          title: 'CRM',
          content: this.crm,
          link: '/koppelingen/crm',
        },
        {
          title: 'ERP',
          content: this.erp,
          link: '/koppelingen/erp',
        },
        {
          title: 'CMS',
          content: this.cms,
          link: '/koppelingen/cms',
        },
      ],
    };
  },
};
</script>

<style lang="sass" scoped>
.link-item
  background: url("~assets/img/bg-linkitems.svg") no-repeat center / cover
</style>
