<template>
  <div>
    <header-row
      :logo="require('~/assets/img/logo-sub.svg')"
      :image-url="require('~/assets/lottie/cloud.svg')"
      :content="page.ondertitel"
    />
    <card
      class="mt-6 lg:-mt-40"
    />
    <testimonials class="hidden lg:block" :header="page.testimonial_header"/>
    <text-block
      :image-url="require('assets/img/bericht.svg')"
      :title="page.kop_1"
      :content="page.tekst_1"
    />
    <link-items :crm="page.crm" :erp="page.erp" :cms="page.cms"/>
    <usps
      class="usp-home -mt-152 sm:-mt-200 lg:-mt-152 xl:-mt-200"
    />
    <text-block
      :image-url="require('assets/img/marketing.svg')"
      :reverse="true"
      :title="page.kop_2"
      :content="page.tekst_2"
      class="-mt-48"
    />
    <testimonials class="lg:hidden"/>
  </div>
</template>

<script>
import Card from '~/components/Card';
import HeaderRow from '~/components/HeaderRow';
import LinkItems from '~/components/LinkItems';
import Testimonials from '~/components/Testimonials';
import TextBlock from '~/components/TextBlock';
import Usps from '~/components/Usps';
import page from '~/plugins/mixins/page';

export default {
  components: {
    Card,
    HeaderRow,
    LinkItems,
    Testimonials,
    TextBlock,
    Usps,
  },
  mixins: [page],
};
</script>

<style lang="sass" scoped>
.usp-home
  @media(min-device-width: theme('screens.sm')) and (max-device-width: theme('screens.lg')) and (orientation: landscape)
    margin-top: -44rem
</style>
